let Cookies = require("js-cookie");
require("../plugins/bioep.min.js");

if (Cookies.get("signed_up") !== "true") {
    bioEp.init({
        delay: 0,
        showOnDelay: false,
        cookieExp: 0.25,
        showOncePerSession: false
    });
}
